export default class Stamp {
    constructor() {
        this.canvasW = 320;
        this.canvasH = 464;
        this.camera = document.createElement("video");
        this.camera.setAttribute("autoplay", "");
        this.camera.setAttribute("muted", "");
        this.camera.setAttribute("playsinline", "");
        this.capCanvas = null;
        this.capContext = null;
        this.eventFunc = null;
        this.mode = null;
        this.frameImg = null;
        this.isFrontCamera = false;
        this.targetImg = null;
        this.targetImages = null;
        this.imageRatio = 0.5624;
        // this.imageRatio = 1;
        this.marginTopPar = -0.1;
        // this.marginTopPar = 0.0;
        this.lastedImageData;
    }

    async checkUseDevice() {
        try {
            let stream = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: false
            });
            if (stream) {
                stream.getVideoTracks().forEach((track) => {
                    track.stop();
                });
                stream.getAudioTracks().forEach((track) => {
                    track.stop();
                });
            }
            return true;
        } catch (e) {
            console.log(e);
            // switch (e.name) {
            //     case "NotAllowedError":
            throw e;
            // }
        }
        return false;
    }

    // async qrCamera(target, evfunc, startFunc) {
    //     this.stopCamera();
    //     this.mode = "qr";

    //     try {
    //         if (target.tagName.toLowerCase() == "canvas") {
    //             this.capCanvas = target;
    //             this.targetImg = null;
    //         } else if (target.tagName.toLowerCase() == "img") {
    //             this.targetImg = target;
    //             this.capCanvas = document.createElement("canvas");
    //             this.capCanvas.width = 320;
    //             this.capCanvas.height = 464;
    //         } else {
    //             throw new Error("target tag type error")
    //         }
    //     } catch (error) {
    //         throw error;
    //     }


    //     this.capContext = this.capCanvas.getContext('2d');
    //     this.eventFunc = evfunc;
    //     this.canvasW = this.capCanvas.width;
    //     this.canvasH = this.capCanvas.height;
    //     try {
    //         await this.createCamera(false, startFunc);
    //     } catch (error) {
    //         throw error;
    //     }
    // }

    loadImage(src) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = (e) => reject(e);
            img.src = src;
        });
    }

    async shotCamera(target, frameData, startFunc, isChangeFrame, targetList) {
        if (!target) {
            throw new Error("target not find error")
        }

        this.targetImages = targetList;

        if (!isChangeFrame) {
            this.stopCamera();
        }
        this.mode = "shot";



        try {
            if (typeof (frameData) == "string") {
                this.frameImg = await this.loadImage(frameData);
                if (!this.frameImg) throw new Error("image error");
            } else if (frameData instanceof HTMLImageElement) {
                this.frameImg = frameData;
            } else {
                throw new Error("not image data")
            }
        } catch (error) {
            console.log(error);
            this.frameImg = null;
            throw new Error("not load frame");
        }


        if (target.tagName.toLowerCase() == "canvas") {
            this.capCanvas = target;
            this.targetImg = null;
            this.capCanvas.height = this.capCanvas.width * (this.frameImg.height / this.frameImg.width);
        } else if (target.tagName.toLowerCase() == "img") {
            this.targetImg = target;
            this.capCanvas = document.createElement("canvas");
            this.capCanvas.width = this.frameImg.width / 2;
            this.capCanvas.height = this.frameImg.height / 2;
        } else {
            throw new Error("target tag type error")
        }

        this.capContext = this.capCanvas.getContext('2d');
        this.canvasW = this.capCanvas.width;
        this.canvasH = this.capCanvas.height;

        if (!isChangeFrame) {
            await this.createCamera(true, startFunc, isChangeFrame);
        } else {
            startFunc.apply(null, [true]);
        }
    }

    async changeFrame(frameData) {
        try {
            if (typeof (frameData) == "string") {
                this.frameImg = await this.loadImage(frameData);
                if (!this.frameImg) throw new Error("image error");
            } else if (frameData instanceof HTMLImageElement) {
                this.frameImg = frameData;
            }
            this.capContext.clearRect(0, 0, this.canvasW, this.canvasH);
        } catch (error) {
            console.log(error);
            this.frameImg = null;
            throw new Error("not load frame");
        }
    }

    async createCamera(useFront, startFunc) {

        if (!this.camera) {
            return;
        }

        let stream = this.camera.srcObject;
        if (stream) {
            stream.getVideoTracks().forEach((track) => {
                track.stop();
            });
            stream.getAudioTracks().forEach((track) => {
                track.stop();
            });
        }

        stream = undefined;

        let facingMode = {
            width: { ideal: 1280 },
            facingMode: "user"
        };
        if (!useFront) {
            facingMode = {
                width: { ideal: 1280 },
                facingMode: { exact: "environment" }
            };
            this.isFrontCamera = false;
        } else {
            this.isFrontCamera = true;
        }

        try {
            stream = await navigator.mediaDevices.getUserMedia({ video: facingMode });
        } catch (e) {
            // console.log(e);
        }
        if (stream == undefined) {
            try {
                stream = await navigator.mediaDevices.getUserMedia({ video: { width: { ideal: 1280 } } });
                this.isFrontCamera = true;
            } catch (error) {
                throw error;
            }
        }
        // console.log("BBB", stream);

        try {
            this.camera.srcObject = stream;
            this.camera.onloadedmetadata = (e) => {
                this.camera.play();
                this.cameraUpdate();
                if (startFunc) {
                    startFunc.apply(null, [true]);
                }
            };

        } catch (e) {
            console.log(e);
        }

    }

    switchCamera(startFunc) {
        this.isFrontCamera = !this.isFrontCamera;
        this.createCamera(this.isFrontCamera, startFunc);
    }

    getShotCameraData(targetImg) {
        const bigCanvas = document.createElement("canvas");
        const bigContext = bigCanvas.getContext('2d');
        let sx, sy, sw, sh;

        try {

            let vw, vh;
            const imgW = this.frameImg.width;
            const imgH = this.frameImg.height;

            if (!targetImg) {
                targetImg = this.camera;
                vw = this.camera.videoWidth;
                vh = this.camera.videoHeight;

                this.lastedImageData = document.createElement("canvas");
                const bkContext = this.lastedImageData.getContext('2d');
                this.lastedImageData.width = vw;
                this.lastedImageData.height = vh;
                bkContext.drawImage(this.camera, 0, 0);

            } else {
                vw = targetImg.width;
                vh = targetImg.height;
            }
            console.log(vw, vh);

            if (!vw || !vh) {
                throw new Error();
            }

            if (vw < vh) {
                // 縦長
                sw = vw;
                sh = vw / this.imageRatio;
                sy = (vh - sh) / 2;
                sx = 0;
            } else {
                // 横長
                sw = vh * this.imageRatio;
                sh = vh;
                sy = 0;
                sx = (vw - sw) / 2;
            }




            bigCanvas.width = imgW;
            bigCanvas.height = imgH;



            if (this.mode == "shot" && this.isFrontCamera) {
                // フロントは反転する
                bigContext.save();
                bigContext.translate(imgW, 0);
                bigContext.scale(-1, 1);
                bigContext.drawImage(targetImg, sx, sy, sw, sh, 0, imgH * this.marginTopPar, imgW, imgW / this.imageRatio);

                bigContext.restore()
            } else {
                bigContext.drawImage(targetImg, sx, sy, sw, sh, 0, imgH * this.marginTopPar, imgW, imgW / this.imageRatio);
            }

            bigContext.drawImage(this.frameImg, 0, 0, imgW, imgH, 0, 0, bigCanvas.width, bigCanvas.height);

            return bigCanvas.toDataURL("image/jpeg")

        } catch (error) {
            console.log(error);
        }

        return null;
    }
    async getChangeShotCameraData(frameData) {
        await this.changeFrame(frameData);
        return await this.getShotCameraData(this.lastedImageData);
    }

    stopCamera() {
        if (!this.camera) {
            return;
        }
        let stream = this.camera.srcObject;
        if (stream) {
            stream.getVideoTracks().forEach((track) => {
                track.stop();
            });
            stream.getAudioTracks().forEach((track) => {
                track.stop();
            });
        }
        this.camera.srcObject = null;
        this.eventFunc = null;
        this.mode = null;
        this.frameImg = null;
        this.lastedImageData = undefined;
    }


    async cameraUpdate() {
        if (this.camera.srcObject == null) {
            console.log("srcObject END");
            return;
        }
        let sx, sy, sw, sh;

        try {

            let vw = this.camera.videoWidth;
            let vh = this.camera.videoHeight;

            if (!vw || !vh) {
                throw new Error();
            }

            if (vw < vh) {
                // 縦長
                sw = vw;
                sh = vw / this.imageRatio;
                sy = (vh - sh) / 2;
                sx = 0;
            } else {
                // 横長
                sw = vh * this.imageRatio;
                sh = vh;
                sy = 0;
                sx = (vw - sw) / 2;
            }

            if (this.mode == "shot" && this.isFrontCamera) {
                // フロントは反転する
                this.capContext.save();
                this.capContext.translate(this.canvasW, 0);
                this.capContext.scale(-1, 1);
                this.capContext.drawImage(this.camera, sx, sy, sw, sh, 0, this.canvasH * this.marginTopPar, this.canvasW, this.canvasW / this.imageRatio);
                this.capContext.restore();
            } else {
                this.capContext.drawImage(this.camera, sx, sy, sw, sh, 0, this.canvasH * this.marginTopPar, this.canvasW, this.canvasW / this.imageRatio);
            }

            this.capContext.drawImage(this.frameImg, 0, 0, this.frameImg.width, this.frameImg.height, 0, 0, this.canvasW, this.canvasH);

            if (!this.targetImages && this.targetImg) {
                this.targetImg.src = this.capCanvas.toDataURL("image/jpeg");
            }

            requestAnimationFrame(() => {
                this.cameraUpdate();
            });
        } catch (e) {
            console.log(e);
        }



    }

}