<template>
  <v-container>
    <v-row class="text-center fixmargin">
      <v-col cols="12" class="text-center ma-0 pa-0 fixtitle">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100vw"
          viewBox="0 0 390 80">
          <g transform="translate(0 0)">
            <image width="390" height="80" href="img/common/title.jpg"></image>
          </g>
        </svg>
      </v-col>
    </v-row>

    <v-row class="ma-5 pa-0 ml-10 mr-10">
      <v-col cols="12" class="text-center ma-0 pa-0 pb-1" v-show="!isShotFreeze">
        <img ref="capImage" src="/img/photo/framedummy.png" id="capImage" />
      </v-col>
      <v-col cols="12" class="text-center ma-0 pa-0 pb-1" v-show="isShotFreeze">
        <img id="photoImage" ref="photoImage" src="" />
      </v-col>
      <v-col cols="6" class="pa-0 ma-0 pr-2">
        <div class="btnNormal btnBlue" @click="execShot" v-if="!isShotFreeze">
          <div inert>撮影する</div>
        </div>
        <div class="btnNormal btnBlue" @click="execRetry" v-else>
          <!-- <div inert>もう一度<br />撮影する</div> -->
          <div inert>撮り直す</div>
        </div>

        <!-- <v-img src="img/photo/btnshot.png" @click="execShot" v-if="!isShotFreeze"></v-img> -->
        <!-- <v-img src="img/photo/btnreshot.png" @click="execRetry" v-else></v-img> -->
      </v-col>
      <v-col cols="6" class="pa-0 ma-0 pl-2">
        <div class="flashAnimeBase">
          <div class="btnNormal btnRed" @click="openChangeWindow" :class="countShotAttention === 1 ? 'flashAnime' : ''">
            <div inert>フォトフレームを<br />切り替える</div>
          </div>
        </div>


        <!-- <v-img src="img/photo/btnchange.png" @click="openChangeWindow"></v-img> -->
      </v-col>
      <v-col cols="12" class="text-center ma-0 pa-0 pt-3 pb-1" inert>
        長押しすると保存できます。
      </v-col>
    </v-row>

    <v-row class="infophotosave ma-0 pa-0" inert v-if="isShotFreeze">
      <v-col cols="12" class="ma-0 pa-0">
        <img src="img/photo/shin.png" width="80%" />
      </v-col>
    </v-row>

    <v-dialog v-model="isOpenChangeWindow" :max-width="dialogMaxWidth" justify="center" class="align-center" persistent>
      <v-card :style="changeWindowBackgroundCss">
        <v-card-actions class="text-center">
          <v-row class="ma-2">
            <v-col cols="6" class="pa-0 ma-0 pr-2">

              <div class="btnMini btnMiniRed" @click="changeFrame">
                <div inert>切り替える</div>
              </div>

              <!-- <v-img src="img/photo/btnchangeexec.png" @click="changeFrame"></v-img> -->
            </v-col>
            <v-col cols="6" class="pa-0 ma-0 pl-2">

              <div class="btnMini btnMiniGray" @click="closeChangeWindow">
                <div inert>閉じる</div>
              </div>

              <!-- <v-img src="img/photo/btnclose.png" @click="closeChangeWindow"></v-img> -->
            </v-col>
          </v-row>

        </v-card-actions>
      </v-card>

    </v-dialog>


  </v-container>
</template>

<style scoped>
svg {
  width: min(800px, 100vw);
}

.btnNormal {
  border: 2px solid black;
  border-radius: 2em;
  font-size: min(3.2vw, 26px);
  font-weight: bold;
  line-height: 1.3em;
  color: black;
  height: 3.7em;
  padding: auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.btnMini {
  border: 1.5px solid black;
  border-radius: 1.5em;
  font-size: min(3.2vw, 16px);
  font-weight: bold;
  line-height: 1.3em;
  color: black;
  height: 2.5em;
  padding: auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.btnBlue {
  background-color: #3BBEE9;
  box-shadow: max(-0.25vw, -2px) max(-0.5vw, -4px) 0px min(0.3vw, 3px) #1390BB inset;
}

.btnRed {
  background-color: #F19069;
  box-shadow: max(-0.25vw, -2px) max(-0.5vw, -4px) 0px min(0.3vw, 3px) #d85b48 inset;
}

.btnMiniRed {
  background-color: #F19069;
  box-shadow: max(-0.12vw, -1px) max(-0.25vw, -2px) 0px min(0.15vw, 1.5px) #d85b48 inset;
}


.btnMiniGray {
  background-color: #E9E9E9;
  box-shadow: max(-0.12vw, -1px) max(-0.25vw, -2px) 0px min(0.15vw, 1.5px) #b8b8b8 inset;
}

.fixtitle {
  position: fixed;
  top: 0;
  left: 0;
}

.fixmargin {
  height: min(176px, 22vw);
}

#capImage,
#photoImage {
  background-color: #aaa;
  border: 8px solid white;
  width: 100%;
}

.v-dialog {
  padding-top: 0 !important;
}

.v-card {
  margin: 0 auto;
  font-family: 'Noto Sans JP', sans-serif;
  padding-top: 1vh;
  padding-bottom: 1vh;
  /* width: 60vw;
  height: calc(60vw*1.83); */
  /* background-color: transparent !important; */
  background-size: cover;
  border-style: solid !important;
  border-width: 5px !important;
  border-color: black;
  border-radius: min(5vw, 40px) !important;
  /* border-image: url(@/assets/images/windowbg.png); */
  /* border-image-slice: 36 fill;
    border-image-repeat: repeat; */
}

.v-card__title {
  display: flex;
  justify-content: center;
  color: white;
  font-size: 1.5em !important;
  text-align: center;
}

.v-card__text {
  text-align: left;
  color: white !important;
  font-size: 1em !important;
  font-weight: normal !important;
}

.v-card__actions {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
}


.infophotosave {
  position: fixed;
  top: 30%;
  left: 0;
  width: 100%;
}

.infophotosave div {
  display: flex;
  justify-content: flex-end;
  animation: slidein 10s ease infinite;

}

@keyframes slidein {

  0%,
  10%,
  50%,
  100% {
    transform: translateX(100vw);
  }

  20%,
  40% {
    transform: translateX(0);
  }


}


.flashAnime {
  animation: flash 3s ease infinite;
}

@keyframes flash {

  0%,
  5%,
  25% {
    opacity: 1;
    transform: scale(1.0, 1.0);
  }

  15% {
    opacity: 0.5;
    transform: scale(1.08, 1.08);
  }
}

/* .flashAnimeBase {
  position: relative;
}

.flashAnime {
  display: block;
  position: relative;
}

.flashAnime::before {
  position: absolute;
  content: '';
  display: inline-block;
  top: -180px;
  left: 0;
  width: 30px;
  height: 100%;
  background-color: #fff;
  animation: kiran 3s ease-in-out infinite;
}

@keyframes kiran {
  0% {
    transform: scale(0) rotate(45deg);
    opacity: 0;
  }

  80% {
    transform: scale(0) rotate(45deg);
    opacity: 0.5;
  }

  81% {
    transform: scale(4) rotate(45deg);
    opacity: 1;
  }

  100% {
    transform: scale(50) rotate(45deg);
    opacity: 0;
  }
} */
</style>
<script>

export default {
  components: {
  },
  data: () => ({
    viewFrame: undefined,
    isOpenChangeWindow: false,
    isArchives: false,
    isActiveCamera: false,
    isShotFreeze: false,
    // isShotAttention: false,
    countShotAttention: 0,
    dialogMaxWidth: 400,
    frameImage: [],
    changeWindowBackgroundCss: undefined
  }),
  created: async function () {
    console.log("PHOTO LOADING")
    // データがあって全問正解してるかチェック
    if (!await this.$store.dispatch("checkAllClear")) {
      this.$router.replace({ name: 'loading', query: { key: this.$store.getters.shopkey } });
      return;
    }


    //フレーム画像DL
    this.loadFrameImage();

    this.isArchives = false;
    this.isActiveCamera = false;
    if (this.dialogMaxWidth * 1.5 > window.innerHeight) {
      this.dialogMaxWidth = window.innerHeight / 1.6;
    }
    window.scrollBy(0, 0);

  },
  mounted: function () {

    // フォトフレームを文字列AとBのどちらかランダムに選択する
    this.viewFrame = Math.floor(Math.random() * 2);
    window.onresize = this.resize;
  },

  destroyed: function () {
    let appDom = document.getElementById("app");
    appDom.classList.remove("photoBgA");
    appDom.classList.remove("photoBgB");
    this.$stamp.stopCamera();
    window.onresize = undefined;
  },
  methods: {
    resize() {
      let css = "";
      css += 'background-image: url("/shops/' + this.$store.getters.shopkey + '/img/popup_' + (this.viewFrame === 0 ? "b" : "a") + '.jpg");';
      let w = window.innerWidth * 0.8;
      let h = w * 1.83;
      if (h > window.innerHeight * 0.8) {
        h = window.innerHeight * 0.8;
        w = h / 1.83;
      }
      css += "width: " + w + "px;"
      css += "height: " + h + "px;"

      this.changeWindowBackgroundCss = css;

    },
    async loadFrameImage() {
      this.frameImage[0] = await this.loadImage("/shops/" + this.$store.getters.shopkey + "/img/frame_a.png");
      this.frameImage[1] = await this.loadImage("/shops/" + this.$store.getters.shopkey + "/img/frame_b.png");
      this.viewShot();
    },
    loadImage(src) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = (e) => reject(e);
        img.src = src;
      });
    },
    async viewShot(count, func) {
      if (!count) count = 1;
      if (this.$refs.capImage) {
        try {
          this.isActiveCamera = false;
          await this.$stamp.shotCamera(this.$refs.capImage, this.frameImage[this.viewFrame], () => {
            this.isActiveCamera = true;
          });
        } catch (error) {
          console.error(error);
          this.$router.replace({ name: 'error', params: { "errorcamera": true }, query: { key: this.$store.getters.shopkey } });
        }
      } else if (count < 5) {
        setTimeout(() => {
          this.viewShot(count + 1, func)
        }, 300);
      }
    },
    switchCamera() {
      this.isActiveCamera = false;
      this.$stamp.switchCamera(() => {
        this.isActiveCamera = true;
      });
    },
    execShot() {
      console.log("execShot");
      try {
        const shotImage = this.$stamp.getShotCameraData();
        if (!shotImage) throw new Error("not image");
        this.$refs.photoImage.src = shotImage;
        this.isShotFreeze = true;
        if (this.countShotAttention == 0) {
          this.countShotAttention = 1;
        }
      } catch (error) {
        console.log(error);
      }

    },
    execRetry() {
      console.log("execRetry");
      try {
        this.isShotFreeze = false;
        if (this.countShotAttention == 1) {
          this.countShotAttention = 0;
        }
        this.$refs.photoImage.src = undefined;
      } catch (error) {
        console.log(error);

      }

    },
    openChangeWindow() {
      this.isOpenChangeWindow = true;
    },
    closeChangeWindow() {
      this.isOpenChangeWindow = false;
    },
    async changeFrame() {
      this.countShotAttention = -1;

      if (this.viewFrame === 1) {
        this.viewFrame = 0;
      } else {
        this.viewFrame = 1;
      }
      if (this.isShotFreeze) {
        const shotImage = await this.$stamp.getChangeShotCameraData(this.frameImage[this.viewFrame]);
        if (!shotImage) throw new Error("not image");
        this.$refs.photoImage.src = shotImage;

      }
      this.closeChangeWindow();
    },
    goLink(url) {
      window.open(url, '_blank');
    },
  },
  watch: {
    viewFrame: function (val) {
      let appDom = document.getElementById("app");
      if (val === 0) {
        appDom.classList.remove("photoBgB");
        appDom.classList.add("photoBgA");
        this.$stamp.changeFrame(this.frameImage[0]);
      } else {
        appDom.classList.remove("photoBgA");
        appDom.classList.add("photoBgB");
        this.$stamp.changeFrame(this.frameImage[1]);
      }
      this.resize();
    }
  }
};
</script>

