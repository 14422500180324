<template>
  <v-container>
    <div class="text-center pt-5">
      <br />
      <br />
      <div class="loadingmsg">読み込み中</div>
      <br />
      <div class="hollow-dots-spinner" style="margin:0 auto">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>
  </v-container>
</template>

<style scoped>
.loadingmsg {
  color: gray;
}

.hollow-dots-spinner,
.hollow-dots-spinner * {
  box-sizing: border-box;
}

.hollow-dots-spinner {
  height: 15px;
  width: calc(30px * 3);
}

.hollow-dots-spinner .dot {
  width: 15px;
  height: 15px;
  margin: 0 calc(15px / 2);
  border: calc(15px / 5) solid gray;
  border-radius: 50%;
  float: left;
  transform: scale(0);
  animation: hollow-dots-spinner-animation 1000ms ease infinite 0ms;
}

.hollow-dots-spinner .dot:nth-child(1) {
  animation-delay: calc(300ms * 1);
}

.hollow-dots-spinner .dot:nth-child(2) {
  animation-delay: calc(300ms * 2);
}

.hollow-dots-spinner .dot:nth-child(3) {
  animation-delay: calc(300ms * 3);

}

@keyframes hollow-dots-spinner-animation {
  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
<script>

export default {
  data: () => ({
    goTimer: undefined
  }),
  created: async function () {
    this.goTimer = setTimeout(() => {
      let lastedPage = this.$store.getters.lastedPage;
      console.log("loadinggopage", lastedPage);
      if (lastedPage == "/" || lastedPage == "/loading") lastedPage = undefined;
      if (lastedPage) {
        this.$router.replace({ path: lastedPage, query: { key: this.$store.getters.shopkey } });
      } else {
        this.$router.replace({ name: 'start', query: { key: this.$store.getters.shopkey } });
      }
    }, 100);
  },
  mounted: function () {
  },
  destroyed: function () {
    clearTimeout(this.goTimer);
  },
  methods: {

  },
  computed: {

  }
}
</script>

