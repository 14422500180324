import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import Stamp from './static/stamp'
import VueCryptojs from 'vue-cryptojs'


import VueFlicking from "@egjs/vue-flicking";
import "@egjs/vue-flicking/dist/flicking.css";
// import { Arrow } from "@egjs/flicking-plugins";
// import "@egjs/flicking-plugins/dist/arrow.css";

// VueFlicking.addPlugins(new Arrow());

Vue.config.productionTip = false
Vue.prototype.$stamp = new Stamp();
Vue.use(VueCryptojs)
Vue.use(VueFlicking);
// Vue.use(Arrow);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
