import Vue from 'vue'
import VueRouter from 'vue-router'

import LoadingView from '../views/LoadingView.vue'
import StartView from '../views/StartView.vue'
import PresentView from '../views/PresentView.vue'
import PhotoView from '../views/PhotoView.vue'
import ErrorView from '../views/ErrorView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'loading',
    component: LoadingView
  },
  {
    path: '/start',
    name: 'start',
    component: StartView
  },
  {
    path: '/present',
    name: 'present',
    component: PresentView
  },
  {
    path: '/photo',
    name: 'photo',
    component: PhotoView
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorView
  },
  {
    path: '*',
    name: 'allerror',
    component: ErrorView
  }
]

const router = new VueRouter({
  mode: 'history',
  // mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // if (savedPosition) {
    //   return savedPosition
    // } else {
    return { x: 0, y: 0 }
    // }
  }
})

export default router
