<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
    <v-footer padless color="transparent" inert>
      <v-col class="text-center pt-5 pb-10 footertext" cols="12">
        &copy; U／SCS <span class="footerbox">NOT FOR SALE</span>
      </v-col>
    </v-footer>
  </v-app>
</template>
<style></style>
<style scoped>
.footertext {
  font-family: 'Inter', sans-serif;
  font-size: max(1vw, 12px);
  font-weight: normal;
  color: black;
  vertical-align: middle;
}

.footerbox {
  font-size: max(0.8vw, 10px);
  border: 1px solid black;
  padding: 0.3vw 0.5vw 0.3vw 0.6vw;
  margin-left: 0.8vw;
}


@keyframes slideIn {
  0% {
    transform: translateY(-100px);
  }

  100% {
    transform: translateY(0px);
  }
}
</style>
<script>
import axios from "axios";

export default {
  name: "App",

  data: () => ({
    localStorageKey: undefined
  }),
  created: async function () {
    // url paramの処理
    let param = await this.$store.dispatch("checkParam");

    // ショップの設定を取得
    try {
      if (!this.$store.getters.shopkey) {
        throw new Error("no set shopkey");
      }
      let res = await axios.get("/shops/" + this.$store.getters.shopkey + "/setting.json");
      if (typeof res.data == "string" || !res.data.name || !res.data.keywords) {
        throw new Error("no data");
      }
      this.$store.commit("shop", res.data);
    } catch (error) {
      if (this.nowPath != "/error")
        this.$router.replace({ name: 'error', params: { 'errormessage': "店舗が特定できませんでした。" }, query: { key: this.$store.getters.shopkey } });
      return;
    }

    // ローカルにあるデータ
    try {
      this.localStorageKey = this.$store.getters.hashPrefix + "_" + this.$store.getters.shopkey + "_data";

      // テストで毎回リセットかける
      if (param.get("reset") == 1) {
        localStorage.removeItem(this.localStorageKey);
      }

      let data = JSON.parse(localStorage.getItem(this.localStorageKey));
      console.log("☆LOCAL DATA", data);
      if (data) {
        this.$store.commit("init", data);
      }
    } catch (error) {
      console.log(error);
    }

    // storeに登録された時のアクション
    this.$store.subscribe((mutation, state) => {
      console.log("-- store subscribe", this.localStorageKey, mutation, state);
      let noEventType = ["init"];

      if (noEventType.indexOf(mutation.type) == -1) {
        let saveData = {
          "checkTos": state.checkTos,
          "clears": state.clears,
          "lastedPage": state.lastedPage,
        };
        localStorage.setItem(this.localStorageKey, JSON.stringify(saveData));
      }

      // クリアした時にgoogle analyticsに送信
      if (mutation.type.indexOf("clearKeyword") > -1) {
        let clears = this.$store.getters.clears;
        let clearCount = 0;
        for (let key in clears) {
          if (clears[key] == true) {
            clearCount++;
          }
        }
        gtag('event', this.$store.getters.shopkey, { 'event_category': mutation.type, 'event_label': clearCount });
      }

    });

    if (this.$store.getters.isAllClear == true) {
      if (this.nowPath != "/present")
        this.$router.replace({ name: 'present', query: { key: this.$store.getters.shopkey } });
      return;
    }

    // if (this.nowPath != this.$store.getters.lastedPage) {
    //   console.log("★MOVE PAGE", this.nowPath, this.$store.getters.lastedPage);
    // }
    // ページがstartじゃなかったら移動
    // if (this.nowPath != "/start") {
    //   this.$router.replace({ name: 'start', query: { key: this.$store.getters.shopkey } });
    // }
    if (this.nowPath == "/error") {
      this.$router.replace({ name: 'loading', query: { key: this.$store.getters.shopkey } });
    }



  },
  methods: {

  },
  computed: {
    nowPath() {
      return this.$route.path;
    }
  },
  watch: {
    nowPath(newVar) {
      if (newVar == "/start" || newVar == "/present" || newVar == "/photo") {
        console.log("App.vue", "SAVE PAGE", newVar);
        this.$store.commit("setLastedPage", newVar);
        // コンテンツ表示時はgoogle analyticsに送信
        gtag('event', this.$store.getters.shopkey, { 'event_category': 'page', 'event_label': newVar });
      } else if (newVar == "/error") {
        gtag('event', this.$store.getters.shopkey, { 'event_category': 'error', 'event_label': this.$route.params.errormessage });
      }
      console.log("App.vue", "★CHANGE PAGE", newVar);
    }
  }
};
</script>
