<template>
  <v-container>

    <v-row class="text-center pt-5 pl-10 pr-10">
      <v-col cols="12">
        <img src="img/error/shin.jpg" class="shinmessage">
      </v-col>
      <v-col cols="12">
        <ul class="errormsg">
          <li v-for="message in errorMessageList">{{ message }}</li>
          <li v-if="cameraerrorpage">
            iOSの場合は下記の＜戻る＞を押してから表示されるカメラ利用の許可を押してください。
          </li>
          <li v-if="cameraerrorpage">
            Androidの場合は以下のリンクを参考に設定を変更してから＜戻る＞を押してください。<a
              href='https://support.google.com/chrome/answer/2693767?hl=ja&co=GENIE.Platform%3DAndroid'
              target="_blank">Google Chrome ヘルプ：カメラとマイクを使用する</a>
          </li>
        </ul>
      </v-col>
      <v-col cols="12">
        <img src="img/error/btnback.png" v-if="historyCount != 1" @click="goBack" class="btnback">
      </v-col>
      <v-col cols="12" class="text-center ma-0 pa-0 pt-10 mt-10">
        <a href="https://www.shinchan-movie.com" target="_blank"><img src="img/common/moviebanner.jpg" width="80%" /></a>
      </v-col>
    </v-row>

  </v-container>
</template>
<style scoped>
.shinmessage {
  margin-top: min(40px, 5vw);
  width: min(320px, 40vw);
}

.errormsg {
  text-align: left;
  margin-left: min(40px, 5vw);
  font-family: 'Noto Sans JP', sans-serif;
  font-size: min(24px, 3vw);
}

.btnback {
  margin-top: min(40px, 5vw);
  width: min(320px, 40vw);
}
</style>

<script>
export default {
  data: () => ({
    errorMessageList: ["QRコードをもう一度読んでください。"],
    historyCount: -1,
    cameraerrorpage: false
  }),
  created: function () {
    this.historyCount = history.length;
    if (this.$route.params.errormessage) {
      this.errorMessageList.unshift(this.$route.params.errormessage);
    }
    if (this.$route.params.errorcamera) {
      this.errorMessageList = ["カメラデバイスの利用ができませんでした。"];
      this.cameraerrorpage = true;
    }
  },
  methods: {
    async goBack() {
      // history.back();
      window.location.href = window.location.href;
    },
  },
}
</script>
