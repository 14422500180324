<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-center ma-0 pa-0 bgbar">
        <div class="spaceZero wallpaper">
          <img :src="wallpaperPath" v-if="wallpaperPath" />
        </div>
        <div class="spaceZero">
          <img src="img/present/bar.png" width="100%" />
        </div>
      </v-col>

      <v-col cols="12" class="text-center ma-0 pa-0" inert>
        <div class="shininfo" style="background-image: url('img/present/shin.png');"></div>
      </v-col>
      <v-col cols="12" class="text-center ma-0 pa-0" inert>
        <div ref="scrollAnime" class="scrollAnime"></div>
      </v-col>

      <v-col cols="12" class="text-center ma-0 pa-0 titleArea" inert>
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100vw"
          viewBox="0 0 390 690">
          <g transform="translate(0 0)">
            <image width="390" height="80" href="img/common/title.jpg"></image>
          </g>
        </svg>
      </v-col>

      <v-col cols="12" class="text-center mt-15 pt-15 mb-15 pb-15 ma-0 pa-0" ref="gophotoarea">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100vw"
          viewBox="0 0 390 166">
          <g transform="translate(0 0)">
            <image width="390" height="166" href="img/present/btngophotoall.jpg"></image>
          </g>
          <rect x="75" y="65" width="241" height="57" fill="transparent" @click="goPhoto()" />

        </svg>
      </v-col>

    </v-row>
  </v-container>
</template>

<style scoped>
svg {
  width: min(800px, 100vw);
}

.spaceZero {
  white-space: nowrap;
  word-break: break-all;
  vertical-align: bottom;
  display: inline-block;
  margin: 0 !important;
  padding: 0 !important;
}

.wallpaper {
  animation: fadeIn 1s ease-in-out forwards;
}

.spaceZero>img {
  display: block;
  width: min(800px, 100vw);

}

.bgbar {
  display: flex;
  flex-direction: column;
  width: min(800px, 100vw);
  background-color: #FFD835;
}


.titleArea {
  position: fixed;
  top: 0;
  left: 0;
  transform: translateY(-19.3vw);
  animation: slidein 1s ease forwards;
}

.titleimg {
  width: 100vw;
}

@keyframes slidein {
  0% {
    transform: translateY(-19.3vw);
  }

  100% {
    transform: translateY(0);
  }
}

.shininfo {
  position: fixed;
  top: min(216px, 27vw);
  left: 5vw;
  width: min(300px, 40vw);
  height: min(400px, 50vw);
  background-size: contain;
  animation: fadeIn 1s ease-in-out forwards, flyAnime 5s ease infinite;
  animation-delay: 1s;
  opacity: 0;
}


@keyframes flyAnime {
  0% {
    transform: translate(0px, 0px);
  }

  50% {
    transform: translate(-6px, 10px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.scrollAnime {
  position: fixed;
  bottom: 3vw;
  left: 2vw;
  background-image: url(@/assets/img/scrollarrow.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 10vw;
  height: 12vw;
}

.fadeIn {
  animation: fadeIn 0.3s ease-in-out forwards;
}

.fadeOut {
  animation: fadeOut 0.3s ease-in-out forwards;
}
</style>
<script>

export default {
  components: {
  },
  data: () => ({
    scobserver: undefined,
    sctarget: undefined,
    wallpaperPath: undefined,
  }),
  created: async function () {
    // データがあって全問正解してるかチェック
    if (!await this.$store.dispatch("checkAllClear")) {
      this.$router.replace({ name: 'loading', query: { key: this.$store.getters.shopkey } });
      return;
    }
    window.scrollBy(0, 0);

    // 下までscrollしたときのアクション
    // window.addEventListener('scroll', this.scrollEvent);


  },
  mounted: function () {

    this.sctarget = this.$refs.gophotoarea;
    this.scobserver = new IntersectionObserver((entry) => {
      entry.forEach((item) => {
        if (item.target === this.sctarget) {
          if (item.isIntersecting) {
            this.$refs.scrollAnime.classList.add("fadeOut");
            this.$refs.scrollAnime.classList.remove("fadeIn");

          } else {
            this.$refs.scrollAnime.classList.add("fadeIn");
            this.$refs.scrollAnime.classList.remove("fadeOut");
          }
        }
      });
    }, {
      root: null, //ターゲットが見えるかどうかを確認するためのビューポート, nullはブラウザビューポート
      rootMargin: '0px', //rootの周りのマージンの値
      threshold: 0.3 //どの程度表示されたか
    });
    this.scobserver.observe(this.sctarget);

    this.wallpaperPath = "shops/" + this.$store.getters.shopkey + "/img/wallpaper.jpg";
  },

  destroyed: function () {
    if (this.scobserver) {
      this.scobserver.unobserve(this.sctarget);
    }
    // window.removeEventListener('scroll', this.scrollEvent);

  },
  methods: {
    goPhoto() {
      this.$router.push({ name: 'photo', query: { key: this.$store.getters.shopkey } });
    },
    // scrollEvent() {
    //   // 対象のdivが表示されたか
    //   // console.log("scroll", window.scrollY, this.$refs.gophotoarea.offsetHeight);



    //   // console.log("scroll", (window.innerHeight + window.scrollY), document.body.offsetHeight);
    //   // if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight * 0.8) {
    //   //   this.$refs.scrollAnime.classList.add("fadeOut");
    //   //   this.$refs.scrollAnime.classList.remove("fadeIn");
    //   // } else {
    //   //   this.$refs.scrollAnime.classList.add("fadeIn");
    //   //   this.$refs.scrollAnime.classList.remove("fadeOut");
    //   // }
    // }
  },
  watch: {

  }
};
</script>

