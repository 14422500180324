<template>
    <v-container>
        <v-row class="text-center fixmargin" inert>
            <v-col cols="12" class="text-center ma-0 pa-0 fixtitle">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100vw"
                    viewBox="0 0 390 100">
                    <g transform="translate(0 0)">
                        <image width="390" height="5" href="img/top/bar.png"></image>
                    </g>
                </svg>
            </v-col>
        </v-row>
        <!-- <v-row class="topfixarea">
            <v-col cols="12" class="text-center ma-0 pa-0 fixtitle">
                <img src="img/top/bar.png" style="width: 100%;" />
            </v-col>
        </v-row> -->
        <v-row>
            <v-col cols="12" class="text-center ma-0 pa-0">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100vw"
                    viewBox="0 0 390 668" ref="otherarea">
                    <g transform="translate(0 0)">
                        <image width="390" height="486.5" href="img/top/title.jpg"></image>
                    </g>
                    <g transform="translate(33 499)">
                        <image width="324" height="165" href="img/top/areakeyword.jpg"></image>
                    </g>

                    <g transform="translate(52 569)" v-if="wordoffImgs.length == 5">
                        <g transform="translate(0 0)">
                            <image width="54" height="54" :href="wordoffImgs[0].src" v-if="!wordClaers[0]"></image>
                            <image width="54" height="54" :href="wordonImgs[0].src" v-if="wordClaers[0]"></image>
                        </g>
                        <g transform="translate(58 0)">
                            <image width="54" height="54" :href="wordoffImgs[1].src" v-if="!wordClaers[1]"></image>
                            <image width="54" height="54" :href="wordonImgs[1].src" v-if="wordClaers[1]"></image>
                        </g>
                        <g transform="translate(116 0)">
                            <image width="54" height="54" :href="wordoffImgs[2].src" v-if="!wordClaers[2]"></image>
                            <image width="54" height="54" :href="wordonImgs[2].src" v-if="wordClaers[2]"></image>
                        </g>
                        <g transform="translate(174 0)">
                            <image width="54" height="54" :href="wordoffImgs[3].src" v-if="!wordClaers[3]"></image>
                            <image width="54" height="54" :href="wordonImgs[3].src" v-if="wordClaers[3]"></image>
                        </g>
                        <g transform="translate(232 0)">
                            <image width="54" height="54" :href="wordoffImgs[4].src" v-if="!wordClaers[4]"></image>
                            <image width="54" height="54" :href="wordonImgs[4].src" v-if="wordClaers[4]"></image>
                        </g>
                    </g>
                </svg>
            </v-col>
            <v-col cols="12" class="text-center ma-0 pa-0 mb-10">
                <span id="inputkeyarea" :class="inputKeyCss">
                    <input type="text" minlength="1" maxlength="1" v-model="inputKey" id="inputkeyword" ref="inputkeyword"
                        placeholder="キーワードを入力すれば～？" @focus="textFocusEvent()" @blur="textBlurEvent()"
                        :disabled="inputDisabled">
                </span>
            </v-col>

            <v-col cols="12" class="text-center ma-0 pa-0">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100vw"
                    viewBox="0 0 390 220">
                    <g transform="translate(81 0)">
                        <image width="209" height="194" href="img/top/footershin.jpg" class="run"></image>
                    </g>

                </svg>
            </v-col>
            <v-col cols="12" class="text-center ma-0 pa-0 mt-10">
                <a href="https://www.shinchan-movie.com" target="_blank"><img src="img/common/moviebanner.jpg"
                        width="80%" /></a>
            </v-col>
        </v-row>
        <v-row v-if="isNextPageAnime">
            <v-col cols="12" class="text-center ma-0 pa-0 pageNextAnime">
                <img :src="wallpaperPath" v-if="wallpaperPath" />
            </v-col>
        </v-row>

    </v-container>
</template>

<style scoped>
svg {
    width: min(800px, 100vw);
}

input:focus {
    outline: none;
}

.fixtitle {
    position: fixed;
    top: 0;
    left: 0;
}

.fixmargin {
    height: min(5px, 0.6vw);
}

.topfix {
    /* position: fixed; */
    top: 0;
    /* left: 50%; */
    width: min(800px, 100vw);
    /* height: min(5px, 0.6vw); */
}

.topfixarea {}

.pageNextAnime {
    position: fixed;
    top: 0;
    left: 0;
    width: min(800px, 100vw);
    background-color: #FFD835;
    opacity: 0;
    animation: whiteBg 1s ease-in-out forwards;
    height: 100vh;
}


.pageNextAnime img {
    width: 100vw;
    animation: zoomIn 2s ease-in-out forwards;
}


#inputkeyword::placeholder {
    color: black;
}

#inputkeyword:focus::-webkit-input-placeholder {
    color: transparent;
}

#inputkeyword:focus::-moz-placeholder {
    color: transparent;
}

#inputkeyword:focus::-ms-input-placeholder {
    color: transparent;
}

#inputkeyword:focus::placeholder {
    color: transparent;
}

#inputkeyword:disabled {
    color: black;
    width: 17em;
    border: 0.15em solid black;
    border-radius: 2em;
    padding: 0.7em 1.4em;
    background-color: #3bbee9;
}

#inputkeyword {
    font-family: 'Noto Sans JP', sans-serif;
    font-size: min(28px, 3.4vw);
    color: black;
    width: 17em;
    border: 0.15em solid black;
    border-radius: 2em;
    padding: 0.7em 1.4em;
    background-color: #3bbee9;
    text-align: center;
    font-weight: bold;
    animation: flybtnAnime 5s 1s ease infinite;
    box-shadow: max(-0.25vw, -2px) max(-0.5vw, -4px) 0px min(0.3vw, 3px) #1390BB inset;


}

#inputkeyarea {
    position: relative;
    /* top: -13.7vw; */
    /* margin-left: 3.2vw; */
}

.inputKeyError input {
    background-color: red !important;
    box-shadow: max(-0.25vw, -2px) max(-0.5vw, -4px) 0px min(0.3vw, 3px) #990000 inset !important;

}

/* .inputKeySame input { */
/* background-color: orange !important; */
/* } */

/* .inputKeyClear input { */
/* background-color: green !important; */
/* } */

/* #inputkeyarea::after {
    position: absolute;
    top: max(-80px, -10vw);
    right: max(-80px, -10vw);
    content: url(@/assets/img/mistake.png);
    animation: roteAnime 7s linear infinite;
} */

.inputKeyError::after {
    position: absolute;
    top: max(-48px, -6vw);
    right: max(-120px, -15vw);
    content: '';
    display: inline-block;
    width: min(240px, 30vw);
    height: min(168px, 21vw);
    background-image: url(@/assets/img/mistake.png);
    background-size: contain;
    vertical-align: middle;
    animation: roteAnime 5s linear infinite;
}

.inputKeySame::after {
    position: absolute;
    top: max(-48px, -6vw);
    right: max(-120px, -15vw);
    content: '';
    display: inline-block;
    width: min(240px, 30vw);
    height: min(168px, 21vw);
    background-image: url(@/assets/img/same.png);
    background-size: contain;
    vertical-align: middle;
    animation: roteAnime 5s linear infinite;
}

.inputKeyClear::after {
    position: absolute;
    top: max(-48px, -6vw);
    right: max(-120px, -15vw);
    content: '';
    display: inline-block;
    width: min(240px, 30vw);
    height: min(168px, 21vw);
    background-image: url(@/assets/img/clear.png);
    background-size: contain;
    vertical-align: middle;
    animation: roteAnime 5s linear infinite;
}

@keyframes flybtnAnime {
    0% {
        transform: scale(1.0, 1.0) translate(0%, 0%);
    }

    4% {
        transform: scale(0.95, 0.95) translate(0%, 0%);
    }

    7% {
        transform: scale(1.05, 0.9) translate(0%, 0.02%);
    }

    13% {
        transform: scale(0.9, 1.05) translate(0%, -0.02%);
    }

    17% {
        transform: scale(1.05, 0.95) translate(0%, 0.005%);
    }

    70% {
        transform: scale(1.0, 1.0) translate(0%, 0%);
    }

    100% {
        transform: scale(1.0, 1.0) translate(0%, 0%);
    }
}

@keyframes roteAnime {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(10deg);
    }

    75% {
        transform: rotate(-10deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.run {
    animation: runAnime 7s infinite;
}

@keyframes runAnime {
    0% {
        transform: translateX(100%) rotate(-10deg);
    }

    15% {
        transform: translateX(-5%) rotate(6deg);
    }

    18% {
        transform: translateX(-5%) rotate(0);
    }

    70% {
        transform: translateX(-5%) rotate(0);
    }

    75% {
        transform: translateX(7%) rotate(-2deg);
    }

    85% {
        transform: translateX(-150%) rotate(-2deg);
    }

    100% {
        transform: translateX(-150%) rotate(-2deg);
    }
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    50% {
        opacity: 0.7;
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(0.9);
    }
}

@keyframes whiteBg {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
</style>
<script>
// import TosWindow from "./components/TosWindow";

export default {
    components: {
    },
    data: () => ({
        dialogTos: true,
        wordoffImgs: [],
        wordonImgs: [],
        wordClaers: [],
        inputKey: "",
        textFocus: false,
        textTimer: undefined,
        inputKeyCss: "",
        inputKeyCssTimer: undefined,
        inputKeyChatteringTimer: undefined,
        beforeInputKey: "",
        isNextPageAnime: false,
        inputFocusCount: 0,
        inputDisabled: false,
        inputFocusChatteringTimer: undefined,
        wallpaperPath: undefined,
    }),
    created: async function () {
        if (await this.$store.dispatch("checkAllClear")) {
            this.$router.replace({ name: 'present', query: { key: this.$store.getters.shopkey } });
        }
    },
    mounted: function () {
        if (this.$store.getters.isCheckTos == true) {
            this.dialogTos = false;
        }
        this.wordClaers = this.$store.getters.clears;
        let keywords = this.$store.getters.keywords;
        let shopkey = this.$store.getters.shopkey;
        // 読み込みがおかしい時
        if (keywords.length != 5) {
            this.$router.replace({ name: 'loading', query: { key: this.$store.getters.shopkey } });
        }

        for (let i = 0; i < keywords.length; i++) {
            let preloadOff = new Image();
            preloadOff.src = "shops/" + shopkey + "/img/" + keywords[i].off;
            let preloadOn = new Image();
            preloadOn.src = "shops/" + shopkey + "/img/" + keywords[i].on;

            this.wordonImgs.push(preloadOn);
            this.wordoffImgs.push(preloadOff);
        }

        this.wallpaperPath = "shops/" + this.$store.getters.shopkey + "/img/wallpaper.jpg";
        // this.$router.beforeEach((to, from, next) => {
        //     console.log("★beforeEach", to, from, next)
        //     console.log("animeaaaaaaa");
        //     setTimeout(() => {
        //         console.log("bbbbbb");
        //         next();
        //     }, 10000);
        // })
    },
    destroyed: function () {

    },
    methods: {
        // 利用許諾OK
        // async eventTosOk() {
        //     this.dialogTos = false;
        //     this.$store.commit("setCheckTos", true);
        // },

        // inputCheck() {
        //     console.log("★inputCheck", this.inputKey);
        // }
        textFocusEvent: function () {
            this.textFocus = true;
            clearTimeout(this.inputFocusChatteringTimer);
            this.inputFocusChatteringTimer = setTimeout(() => {
                // console.log("★textFocusEvent", this.beforeInputKey)
                clearTimeout(this.inputKeyCssTimer);
                if (this.beforeInputKey == "") {
                    this.inputKeyCss = "";
                    this.inputKey = "";
                }
            }, 150);
            // this.inputFocusCount++;
            // console.log("★textFocusEvent", this.inputFocusCount);
            // if (this.inputFocusCount > 1) {
            //     return;
            // }
            // console.log("★textFocusEvent 2", this.inputFocusCount);
            // this.inputKeyCss = "";
            // this.inputKey = "";
            // // this.beforeInputKey = "";
            // setTimeout(() => {
            //     this.inputFocusCount = 0;
            //     console.log("★textFocusEvent 3", this.inputFocusCount);
            //     // this.inputDisabled = false;
            // }, 1500);
            // console.log("textFocusEvent", this);

        },
        textBlurEvent: function () {
            this.textFocus = false;
            // console.log("textBlurEvent", this);
        },
        inputKeyExec: async function () {
            console.log("★inputKeyExec", this.inputKey);

            clearTimeout(this.inputKeyCssTimer);

            this.inputKeyCss = "";
            this.beforeInputKey = this.inputKey;
            if (this.inputKey == "") {
                return;
            }


            this.$refs.inputkeyword.blur();
            setTimeout(() => {
                this.beforeInputKey = "";
            }, 500);

            let keywords = this.$store.getters.keywords;
            let clears = this.$store.getters.clears;
            let clearNumber = -1;
            for (let i = 0; i < keywords.length; i++) {
                if (keywords[i].word == this.inputKey) {
                    clearNumber = Number(i);
                }
            }

            // 不正解だったら
            if (clearNumber == -1) {
                this.inputKeyCss = "inputKeyError";
                return;
            }

            // 答え済みだった場合
            if (clears[clearNumber] != false) {
                this.inputKeyCss = "inputKeySame";
                return;
            }

            this.$store.commit("clearKeyword" + (clearNumber + 1), true);
            this.wordClaers = this.$store.getters.clears;
            this.inputKeyCss = "inputKeyClear";

            if (await this.$store.dispatch("checkAllClear")) {
                setTimeout(() => {
                    this.isNextPageAnime = true;
                    setTimeout(() => {
                        this.$router.replace({ name: 'present', query: { key: this.$store.getters.shopkey } });
                    }, 2000);
                }, 1000);
            }


        },
        inputFocusExec: async function () {
            console.log("★inputFocusExec", this.inputKey);
        },
    },
    computed: {
    },
    watch: {
        // textFocus: function (val) {
        //     if (val == false) {
        //         this.inputKey = "";
        //     }
        // },

        inputKey: async function (newVal, oldVal) {
            console.log("★inputKey", newVal, "<-", oldVal);

            clearTimeout(this.inputKeyChatteringTimer);
            this.inputKeyChatteringTimer = setTimeout(() => {
                this.inputKeyExec();
            }, 100);

            // if (newVal == "") {
            //     this.inputKeyChatteringTimer = setTimeout(() => {
            //         // clearTimeout(this.inputKeyCssTimer);
            //         this.inputKeyCss = "";
            //         this.inputKey = "";
            //         this.beforeInputKey = "";
            //     }, 500);
            //     return;
            // }
            // // 前のものと同じだったらスルーする
            // if (this.beforeInputKey == newVal) {
            //     if (this.inputKeyCss == "") {
            //         this.inputKeyCss = "inputKeySame";
            //     }
            //     return;
            // }


            // this.inputKeyCss = "";
            // this.beforeInputKey = newVal;
            // clearTimeout(this.inputKeyCssTimer);
            // this.inputKeyCssTimer = setTimeout(() => {
            //     // this.inputKeyCss = "";
            //     // this.inputKey = "";
            //     this.beforeInputKey = "";
            // }, 1500);

            // // 正誤判定
            // console.log("inputKey", newVal, oldVal);
            // this.$refs.inputkeyword.blur();

            // let keywords = this.$store.getters.keywords;
            // let clears = this.$store.getters.clears;
            // let clearNumber = -1;
            // for (let i = 0; i < keywords.length; i++) {
            //     if (keywords[i].word == newVal) {
            //         clearNumber = Number(i);
            //     }
            // }

            // // this.inputDisabled = true;

            // // 不正解だったら
            // if (clearNumber == -1) {
            //     this.inputKeyCss = "inputKeyError";
            //     return;
            // }

            // // console.log("★clears", clears, clearNumber, clears[clearNumber])
            // // 答え済みだった場合
            // if (clears[clearNumber] != false) {
            //     this.inputKeyCss = "inputKeySame";
            //     return;
            // }

            // this.$store.commit("clearKeyword" + (clearNumber + 1), true);
            // this.wordClaers = this.$store.getters.clears;
            // this.inputKeyCss = "inputKeyClear";


            // // console.log("★clears", newVal, await this.$store.dispatch("checkAllClear"));

            // if (await this.$store.dispatch("checkAllClear")) {

            //     setTimeout(() => {
            //         this.isNextPageAnime = true;
            //         setTimeout(() => {
            //             this.$router.replace({ name: 'present', query: { key: this.$store.getters.shopkey } });
            //         }, 2000);
            //     }, 1000);
            // }


        },
        inputKeyCss: function (newVal, oldVal) {
            if (newVal == "") {
                return;
            }
            clearTimeout(this.inputKeyCssTimer);
            this.inputKeyCssTimer = setTimeout(() => {
                this.inputKeyCss = "";
                this.inputKey = "";
                this.beforeInputKey = "";
            }, 3000);
        }
    }
};
</script>
